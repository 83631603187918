* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #00c3ff;
}

a:hover {
  color: #008cff;
}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  max-height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
  padding: 0 1rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
}

table, th, td {
  padding: 5px;
  border: 1px solid black;
}

header {
  top: 0;
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0 4px 2px -2px rgba(0, 128, 0, .2);
  z-index: 2;
  text-align: center;
}

header > div{
  display: inline-block;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 60px;
}

.controls > a {
  font-weight: bold;
  text-decoration: none;
}

.controls > a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  header {
    position: fixed;
  }
  .container > section {
    padding-top: 60px;
  }
  .controls > a {
    padding: 0 10px;
  }
}

@media (max-width: 1023px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
  header {
    position: fixed;
  }
  .container > section {
    padding-top: 60px;
  }
  .controls > a {
    padding: 0 5px;
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
  header {
    position: static;
  }
  .controls > a {
    padding: 0 5px;
  }
  .container > section {
    padding-top: 0px;
  }
}

.grid > * {
  position: relative;
  padding: 5px;
  box-shadow: 0 0 4px 0px #ccc;
  border-radius: 5px;
  text-align: center;
}

.tehnology {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
}

.tehnology_react {
  background: rgba(0, 0, 0, .5) url('image/logo.react.svg') no-repeat center center;
}
.tehnology_vue {
  background: rgba(0, 0, 0, .5) url('image/logo.vue.svg') no-repeat center center;
  background-size: 70% 70%;
}
.tehnology_javascript {
  background: rgba(0, 0, 0, .5) url('image/logo.js_5.svg') no-repeat center center;
}
.tehnology_jquery {
  background: rgba(0, 0, 0, .5) url('image/logo.jquery.svg') no-repeat center center;
  background-size: 70% 70%;
}
.tehnology_css {
  background: rgba(0, 0, 0, .5) url('image/logo.css_3.svg') no-repeat center center;
  background-size: 70% 70%;
}

.grid_hover > *::after {
  content: '';
  display: block;
  background: url('image/magnify.png') no-repeat center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(46, 204, 113, 0.5);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.grid_hover > *:hover::after {
  cursor: pointer;
  opacity: 1;
  transition: 0.5 all;
}

.arrow-up {
  position: fixed;
  bottom: 15px;
  right: 5px;
}

.arrow-up:hover {
  opacity: 0.8;
}